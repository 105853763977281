import React from "react"
import AppTitle, { AppTitleDivider } from "./AppTitle"
import Sidebar, { SidebarLinks } from "./sidebar/Sidebar"
import { AppContainer } from "./Containers"

// NOTIFICATION
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import "animate.css"

const Layout = ({ children }) => {
  return (
    <>
      <ReactNotification />
      <React.Fragment>
        <AppContainer>
          <Sidebar className="md:hidden">
            <AppTitle />
            {/* <AppTitleDivider /> */}
            <SidebarLinks />
          </Sidebar>
          <AppTitle className="lg:hidden md:block" />
          <div className="h-screen px-16 py-8 md:px-4 md:py-4 w-full">
            <main>{children}</main>
          </div>
        </AppContainer>
      </React.Fragment>
    </>
  )
}

export default Layout
