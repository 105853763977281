import { Link } from "gatsby"
import React from "react"
import { Props } from "../../types/Props"

export function AppTitleDivider() {
  return <hr className="mx-4" />
}

function AppTitleContainer({ children, className }: Props) {
  return (
    <div
      className={`px-4 md:px-2 py-2 md:py-1 bg-primary rounded-br-lg text-center md:rounded-bl-lg md:rounded-br-lg md:w-48 md:mx-auto ${className}`}
    >
      {children}
    </div>
  )
}

export default function AppTitle({ className }: Props) {
  return (
    <AppTitleContainer className={className}>
      <Link
        to="/"
        className={`font-bold text-3xl md:text-xl text-white transition duration-200 hover:text-secondary`}
      >
        Utilizr
      </Link>
    </AppTitleContainer>
  )
}
