import { Link } from "gatsby"
import React from "react"
import { Props } from "../../../types/Props"

function SidebarLinksContainer({ children }: Props) {
  return <div className="px-6 py-4">{children}</div>
}

function FirstLevelLinkContainer({ children, id }: Props) {
  return (
    <div id={id} className="text-2xl text-primary">
      {children}
    </div>
  )
}

function SecondLevelLinkContainer({ children, id }: Props) {
  return (
    <div id={id} className="text-secondary">
      {children}
    </div>
  )
}

export function SidebarLinks() {
  return (
    <SidebarLinksContainer>
      {/* TODO: mettere il div qui sotto come componente e dargli un po' di margin bottom (1 rem è perfetto) */}
      <div>
        <FirstLevelLinkContainer id="base64Title">
          <Link to="/base64" className="underline">
            Base64
          </Link>
        </FirstLevelLinkContainer>
        <SecondLevelLinkContainer id="base64Links">
          <ul className="list-disc px-4">
            <li>
              <Link to="/base64/encode" className="underline">
                Base64 Encode
              </Link>
            </li>
            <li>
              <Link to="/base64/decode" className="underline">
                Base64 Decode
              </Link>
            </li>
          </ul>
        </SecondLevelLinkContainer>
      </div>
      <div>
        <FirstLevelLinkContainer id="stringTitle">
          <Link to="/string" className="underline">
            String
          </Link>
        </FirstLevelLinkContainer>
      </div>
      <div>
        <FirstLevelLinkContainer id="dateTitle">
          <Link to="/date" className="underline">
            Date
          </Link>
        </FirstLevelLinkContainer>
        <SecondLevelLinkContainer id="dateLinks">
          <ul className="list-disc px-4">
            <li>
              <Link to="/date/timestampToDate" className="underline">
                Timestamp to Date
              </Link>
            </li>
            <li>
              <Link to="/date/dateToTimestamp" className="underline">
                Date to Timestamp
              </Link>
            </li>
            <li>
              <Link to="/date/differenceInDays" className="underline">
                Difference in Days
              </Link>
            </li>
          </ul>
        </SecondLevelLinkContainer>
      </div>
    </SidebarLinksContainer>
  )
}

export default function Sidebar({ children, className }: Props) {
  return (
    <div className={`flex flex-col static h-screen w-72 ${className}`}>
      {children}
    </div>
  )
}
