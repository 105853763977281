import { Transition } from "@headlessui/react"
import React from "react"
import { Props } from "../../types/Props"

export function AppContainer({ children }: Props) {
  return <div className="flex md:flex-col bg-white text-black">{children}</div>
}

export function ContentContainer({ children, className = "" }: Props) {
  return (
    <Transition
      show={true}
      enter="transition-opacity duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className={`px-4 mb-4 md:mb-2 ${className}`}>{children}</div>
    </Transition>
  )
}

export function PageTitleContainer({ children }: Props) {
  return <div className="mb-8 md:mb-4 px-4">{children}</div>
}

export function UtilityBoxContainer({ children }: Props) {
  return (
    <div className="px-2 grid gap-3 grid-cols-3 md:grid-cols-1">{children}</div>
  )
}

export function UtilityButtonContainer({ children }: Props) {
  return (
    <div className="flex flex-row justify-start md:justify-center">
      {children}
    </div>
  )
}
